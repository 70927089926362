<template>
  <transition appear name="fade">
    <div class="d-flex flex-column">
      <div class="d-flex justify-content-between">
        <div id="space-filler"></div>
        <appAdminSidebar></appAdminSidebar>
        <appHeader class=""></appHeader>
      </div>
    <div class="container p-0">
      <div v-if="isCardsLoading" class="text-center m-4 bold">loading...</div>
      <div v-if="isCardsLoadError" class="text-center m-4 bold">
        could not load list of cards
      </div>
      <div v-if="isCardsLoaded">
        <div class="d-flex flex-col">
          <div id="limitFilter" class="m-3 d-flex p-2">
            <span class="align-self-center">Number of cards to display:</span>
            <b-form-input
              v-model="limit"
              placeholder="enter number of cards to display"
            />
          </div>

          <div id="searchFilter" class="d-flex p-2 m-3">
            <span class="align-self-center">Search: </span>
            <b-form-input
              v-model="searchCriteria"
              placeholder="search for name or order id"
            />
          </div>
        </div>
        <b-container fluid id="cardList">
          <b-row class="header py-2">
            <b-col class="">userName</b-col>
            <b-col sm="3" class="">email</b-col>
            <b-col class="">Value</b-col>
            <b-col class="">Name</b-col>
            <b-col sm="2" class="p-0">Expiration date</b-col>
            <b-col sm="" class="p-0">Order ID</b-col>
            <b-col cols="1" class="">Ref ID</b-col>
            <b-col cols="2" class="">Status</b-col>
            <b-col class="">Actions</b-col>
          </b-row>
          <template v-for="card in cards">
            <CardListItem v-bind:key="card.code" :card="card"></CardListItem>
          </template>
        </b-container>
      </div>
      <appFooter></appFooter>
    </div>
    </div>
  </transition>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { mapState } from "vuex";
import AdminSidebar from "@/views/admin/AdminSidebar";
import CardListItem from "@/components/CardListItem.vue";

export default {
  components: {
    appHeader: Header,
    appFooter: Footer,
    appAdminSidebar: AdminSidebar,
    CardListItem,
  },
  data() {
    return {
      limit: 20,
      canEditEmail: false,
      newEmail: "",
      cardsEditing: {},
      searchCriteria: "",
    };
  },
  computed: {
    ...mapState({
      currentPage: (state) => state.admin.currentCardsPage,
      cards: (state) => state.admin.cards,
      isCardsLoaded: (state) => state.admin.status.cards.initialized,
      isCardsLoading: (state) => state.admin.status.cards.refreshing,
      isCardsLoadError: (state) => state.admin.status.cards.error,
    }),
  },
  watch: {
    limit(newLimit, oldLimit) {
      this.$store.dispatch("admin/fetchCards", {
        page: this.currentPage,
        limit: newLimit,
        searchCriteria: this.searchCriteria,
      });
    },
    searchCriteria(newCriteria, oldCriteria) {
      this.$store.dispatch("admin/fetchCards", {
        page: this.currentPage,
        limit: this.limit,
        searchCriteria: newCriteria,
      });
    },
  },
  methods: {
    cancelCard: function (code) {
      this.$store
        .dispatch("admin/cancelCard", {
          code: code,
        })
        .then(
          () => {},
          (err) => {
            this.showError("Unknown error");
          }
        )
        .catch(() => {
          this.showError("Unknown error");
        });
    },
    showSuccess: function (message, onClose) {
      this.$bvModal
        .msgBoxOk(message, {
          size: "sm",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then(() => {
          onClose();
        });
    },
    showError: function (message) {
      this.$bvModal.msgBoxOk(message, {
        size: "sm",
        headerClass: "p-2 border-bottom-0",
        footerClass: "p-2 border-top-0",
        okVariant: "danger",
        centered: true,
      });
    },
  },
  created() {
    this.$store.dispatch("admin/fetchCards", {
      page: this.currentPage,
      limit: this.limit,
    });
  },
};
</script>

<style lang="scss">
.header {
  background-color: #f0f9ff;
  border-radius: 16px 16px 0 0;
}

#cardList {
  .header,
  .btn {
    font-weight: 700;
    text-transform: uppercase;
  }

  .btn {
    border-radius: 4px;
    font-size: 9px;
  }
}

.card-row:nth-child(even) {
  background-color: #fafafa;
}
</style>
