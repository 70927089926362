var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { appear: "", name: "fade" } }, [
    _c("div", { staticClass: "d-flex flex-column" }, [
      _c(
        "div",
        { staticClass: "d-flex justify-content-between" },
        [
          _c("div", { attrs: { id: "space-filler" } }),
          _c("appAdminSidebar"),
          _c("appHeader", {})
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "container p-0" },
        [
          _vm.isCardsLoading
            ? _c("div", { staticClass: "text-center m-4 bold" }, [
                _vm._v("loading...")
              ])
            : _vm._e(),
          _vm.isCardsLoadError
            ? _c("div", { staticClass: "text-center m-4 bold" }, [
                _vm._v(" could not load list of cards ")
              ])
            : _vm._e(),
          _vm.isCardsLoaded
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "d-flex flex-col" }, [
                    _c(
                      "div",
                      {
                        staticClass: "m-3 d-flex p-2",
                        attrs: { id: "limitFilter" }
                      },
                      [
                        _c("span", { staticClass: "align-self-center" }, [
                          _vm._v("Number of cards to display:")
                        ]),
                        _c("b-form-input", {
                          attrs: {
                            placeholder: "enter number of cards to display"
                          },
                          model: {
                            value: _vm.limit,
                            callback: function($$v) {
                              _vm.limit = $$v
                            },
                            expression: "limit"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "d-flex p-2 m-3",
                        attrs: { id: "searchFilter" }
                      },
                      [
                        _c("span", { staticClass: "align-self-center" }, [
                          _vm._v("Search: ")
                        ]),
                        _c("b-form-input", {
                          attrs: { placeholder: "search for name or order id" },
                          model: {
                            value: _vm.searchCriteria,
                            callback: function($$v) {
                              _vm.searchCriteria = $$v
                            },
                            expression: "searchCriteria"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c(
                    "b-container",
                    { attrs: { fluid: "", id: "cardList" } },
                    [
                      _c(
                        "b-row",
                        { staticClass: "header py-2" },
                        [
                          _c("b-col", {}, [_vm._v("userName")]),
                          _c("b-col", { attrs: { sm: "3" } }, [
                            _vm._v("email")
                          ]),
                          _c("b-col", {}, [_vm._v("Value")]),
                          _c("b-col", {}, [_vm._v("Name")]),
                          _c(
                            "b-col",
                            { staticClass: "p-0", attrs: { sm: "2" } },
                            [_vm._v("Expiration date")]
                          ),
                          _c(
                            "b-col",
                            { staticClass: "p-0", attrs: { sm: "" } },
                            [_vm._v("Order ID")]
                          ),
                          _c("b-col", { attrs: { cols: "1" } }, [
                            _vm._v("Ref ID")
                          ]),
                          _c("b-col", { attrs: { cols: "2" } }, [
                            _vm._v("Status")
                          ]),
                          _c("b-col", {}, [_vm._v("Actions")])
                        ],
                        1
                      ),
                      _vm._l(_vm.cards, function(card) {
                        return [
                          _c("CardListItem", {
                            key: card.code,
                            attrs: { card: card }
                          })
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _c("appFooter")
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }